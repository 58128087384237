import styled, { css } from 'styled-components'
import Image, { FluidObject } from 'gatsby-image'

export const HeroBgStyles = css`
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`

export const Hero = styled.section`
  position: relative;
  width: 100%;
  min-height: 100vh;
`

export const MobileBackground = styled(Image)<{ fluid: FluidObject }>`
  ${HeroBgStyles}
  position: absolute;
  object-fit: cover;

  ${({ theme }) => theme.mq.tablet} {
    display: none;
  }
`

export const DesktopBackground = styled(Image)<{ fluid: FluidObject }>`
  ${HeroBgStyles}
  position: absolute;
  display: none;
  object-fit: cover;

  ${({ theme }) => theme.mq.tablet} {
    display: initial;
    padding-top: 8rem;
  }
`

export const Mask = styled.div`
  ${HeroBgStyles}
  position: absolute;
  background: ${({ theme }) => theme.heroHomeBackground};

  ${({ theme }) => theme.mq.tablet} {
    left: 0;
    width: 30%;
  }
`

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  max-width: 108rem;
  height: 100vh;
  margin: 0 auto;
  padding: 0 3.7rem;
`

export const Header = styled.h1`
  position: relative;
  color: ${({ theme }) => theme.white};
  font-weight: ${({ theme }) => theme.font.weight.black};
  font-size: ${({ theme }) => theme.font.size.headers.xl};
  line-height: 1.3;
  text-transform: uppercase;

  ${({ theme }) => theme.mq.tablet} {
    max-width: 44rem;
    font-size: ${({ theme }) => theme.font.size.headers.xxl};
  }
`

export const ButtonContainer = styled.div`
  position: absolute;
  bottom: 3.6rem;

  ${({ theme }) => theme.mq.tablet} {
    bottom: 13rem;
  }
`
