import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Image from 'gatsby-image'

import * as S from './offer.styles'

import Button from 'src/components/button/button'

interface OfferProps {}

const Offer: React.FC<OfferProps> = () => {
  const data = useStaticQuery(graphql`
    query {
      firstArticle: file(relativePath: { eq: "offerHomeOne.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      secondArticle: file(relativePath: { eq: "offerHomeTwo.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      thirdArticle: file(relativePath: { eq: "offerHomeThree.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fourthArticle: file(relativePath: { eq: "offerHomeFour.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fifthArticle: file(relativePath: { eq: "offerHomeFive.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sixthArticle: file(relativePath: { eq: "offerHomeSix.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <S.Offer>
      <S.Header>Co oferujemy?</S.Header>
      <S.Article>
        <S.ImageContainer>
          <Image fluid={data.firstArticle.childImageSharp.fluid} />
        </S.ImageContainer>
        <S.ContentContainer>
          <S.ArticleHeader>Samochody poleasingowe</S.ArticleHeader>
          <S.Paragraph>
            Pochodzące z zachodnich spółek leasingowych oraz banków. Co je wyróżnia? Pewne pochodzenie, znana historia
            serwisowania, pełna dokumentacja fotograficzna sprzed importu pojazdu do kraju, opinia zachodniego
            rzeczoznawcy dotycząca stanu pojazdu oraz legalność przebiegu potwierdzona dokumentacją.
          </S.Paragraph>
          <S.ButtonContainer>
            <Button path='/oferta' varaint='arrow' direction='right'>
              Sprawdź ofertę
            </Button>
          </S.ButtonContainer>
        </S.ContentContainer>
      </S.Article>
      <S.Article>
        <S.ImageContainer variant='reversed'>
          <Image fluid={data.secondArticle.childImageSharp.fluid} />
        </S.ImageContainer>
        <S.ContentContainer variant='reversed'>
          <S.ArticleHeader>Samochody używane</S.ArticleHeader>
          <S.Paragraph>
            Wpółpracujemy z zachodnimi salonami sprzedaży oraz AutoLandami z których pochodzą oferowane przez nas
            samochody. Wszystkie pojazdy posiadają pełną dokumentację fotograficzną z dnia zakupu. Przed sprzedażą każdy
            jest szczegółowo sprawdzany pod kątem technicznym oraz wizualnym.
          </S.Paragraph>
          <S.ButtonContainer>
            <Button path='/kim-jestesmy' varaint='arrow' direction='right'>
              Więcej o nas
            </Button>
          </S.ButtonContainer>
        </S.ContentContainer>
      </S.Article>
      <S.Article>
        <S.ImageContainer>
          <Image fluid={data.thirdArticle.childImageSharp.fluid} />
        </S.ImageContainer>
        <S.ContentContainer>
          <S.ArticleHeader>Zamiana samochodu</S.ArticleHeader>
          <S.Paragraph>
            Zastanawiasz się co zrobić ze swoim obecnym samochodem? Poinformuj nas o chęci zamiany na nowszy. Jesteśmy
            otwarci na wszystkie propozycje Naszych klientów. Dbamy aby obie strony były zadowolone.
          </S.Paragraph>
          <S.ButtonContainer>
            <Button path='/oferta' varaint='arrow' direction='right'>
              Sprawdź ofertę
            </Button>
          </S.ButtonContainer>
        </S.ContentContainer>
      </S.Article>
      <S.Article>
        <S.ImageContainer variant='reversed'>
          <Image fluid={data.fourthArticle.childImageSharp.fluid} />
        </S.ImageContainer>
        <S.ContentContainer variant='reversed'>
          <S.ArticleHeader>Gwarancja</S.ArticleHeader>
          <S.Paragraph>
            Na życzenie klienta, każdy sprzedawany u nas samochód może zostać objęty gwarancją w zewnętrznej firmie.
            Szczegółowe informacje na temat gwarancji uzyskacie Państwo w siedzibie naszej firmy.
          </S.Paragraph>
          <S.ButtonContainer>
            <Button path='/kim-jestesmy' varaint='arrow' direction='right'>
              Więcej o nas
            </Button>
          </S.ButtonContainer>
        </S.ContentContainer>
      </S.Article>
      <S.Article>
        <S.ImageContainer>
          <Image fluid={data.fifthArticle.childImageSharp.fluid} />
        </S.ImageContainer>
        <S.ContentContainer>
          <S.ArticleHeader>Zamówienie indywidualne</S.ArticleHeader>
          <S.Paragraph>
            Nasza oferta nie zawiera samochodu, którego szukasz? Znajdziemy go dla Ciebie! Napisz do Nas poprzez
            formularz kontaktowy. Opisz o jakim samochodzie marzysz. Podaj dane kontaktowe abyśmy mogli Cię poinformować
            o znalezionym samochodzie. Złożenie zamówienia nie zobowiązuje Cię do zakupu.
          </S.Paragraph>
          <S.ButtonContainer>
            <Button path='/oferta' varaint='arrow' direction='right'>
              Sprawdź ofertę
            </Button>
          </S.ButtonContainer>
        </S.ContentContainer>
      </S.Article>
      <S.Article>
        <S.ImageContainer variant='reversed'>
          <Image fluid={data.sixthArticle.childImageSharp.fluid} />
        </S.ImageContainer>
        <S.ContentContainer variant='reversed'>
          <S.ArticleHeader>O nas</S.ArticleHeader>
          <S.Paragraph>
            Jesteśmy bezpośrednim importerem samochodów z Europy zachodniej. 20 lat doświadczenia w branży, wiedza oraz
            doświadczenie, wyselekcjonowane samochody z zachodnich salonów oraz spółek bankowych, 100% legalności
            przebiegu oraz pochodzenia, wysoka jakość towarów oraz obsługi. W skrócie JM Car Company
          </S.Paragraph>
          <S.ButtonContainer>
            <Button path='/kim-jestesmy' varaint='arrow' direction='right'>
              Więcej o nas
            </Button>
          </S.ButtonContainer>
        </S.ContentContainer>
      </S.Article>
    </S.Offer>
  )
}

export default Offer
