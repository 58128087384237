import React from 'react'

import Layout from 'src/components/layout/layout'
import Seo from 'src/components/seo'
import Hero from 'src/components/homeSections/hero'
import Offer from 'src/components/homeSections/offer'

interface IndexPageProps {}

const IndexPage: React.FC<IndexPageProps> = () => (
  <Layout>
    <Seo
      title='JM Car Company: Zachodnie samochody używane i poleasingowe'
      description='Oferujemy sprzedaż samochodów osobowych używanych, poleasingowych oraz zamianę. Możliwość zakupu samochodu objętego gwarancją.'
    />
    <Hero />
    <Offer />
  </Layout>
)

export default IndexPage
