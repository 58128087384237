import styled, { css } from 'styled-components'

export const Offer = styled.section`
  max-width: 108rem;
  margin: 0 auto;
  padding: 7rem 3.7rem 0;
`

export const Header = styled.h2`
  margin-bottom: 4rem;
  color: ${({ theme }) => theme.darkgray};
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  font-size: ${({ theme }) => theme.font.size.headers.l};
  text-align: center;

  ${({ theme }) => theme.mq.tablet} {
    margin-bottom: 11rem;
  }
`

export const Article = styled.article`
  position: relative;
  max-width: 50rem;
  margin: 0 auto 7rem;

  :last-child {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.mq.tablet} {
    display: flex;
    align-items: center;
    max-width: initial;
    margin-bottom: 12rem;

    :last-child {
      margin-bottom: 10rem;
    }
  }

  ${({ theme }) => theme.mq.desktop} {
    margin-bottom: 17rem;
  }
`

export const ImageContainer = styled.div<{ variant?: 'reversed' }>`
  ${({ theme }) => theme.mq.tablet} {
    position: absolute;
    width: 100%;
    max-width: 43%;
    padding-left: 2rem;
  }

  ${({ theme }) => theme.mq.bigTablet} {
    padding-left: 4rem;
  }

  ${({ variant }) =>
    variant &&
    css`
      ${({ theme }) => theme.mq.tablet} {
        right: 0;
        padding-right: 2rem;
      }

      ${({ theme }) => theme.mq.bigTablet} {
        right: 4rem;
      }
    `}
`

export const ContentContainer = styled.div<{ variant?: 'reversed' }>`
  padding: 3rem 2rem 0;
  border: 1px solid ${({ theme }) => theme.lightgray};

  ${({ theme }) => theme.mq.tablet} {
    flex-basis: 100%;
    padding: 3rem 2rem 3rem 50%;
  }

  ${({ theme }) => theme.mq.bigTablet} {
    flex-basis: 100%;
    padding-right: 4rem;
  }

  ${({ variant }) =>
    variant &&
    css`
      ${({ theme }) => theme.mq.tablet} {
        padding: 3rem 50% 3rem 2rem;
      }

      ${({ theme }) => theme.mq.bigTablet} {
        padding-left: 6.6rem;
      }
    `}
`

export const ArticleHeader = styled.h3`
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.red};
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  font-size: ${({ theme }) => theme.font.size.headers.l};
`

export const Paragraph = styled.p`
  margin-bottom: 2.6rem;
`

export const ButtonContainer = styled.div`
  width: calc(100% + 4rem);
  margin-left: -2rem;

  a {
    width: 100%;
  }

  ${({ theme }) => theme.mq.tablet} {
    width: auto;
    margin-left: 0;
    a {
      width: auto;
    }
  }
`
